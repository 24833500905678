import React from 'react';

import useHistory from '../locales/useHistory';
import useTranslation from '../locales/useTranslation';

import ErrorScreen from '../modules/common/ErrorScreen';

import notFound from '../images/404.svg';

const NotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ErrorScreen
      icon={notFound}
      title={t['PageNotFound']}
      description={t['ThePageYouAreLookingFor']}
      buttonText={t['GoToHome']}
      buttonProps={{ onClick: () => history.push('/') }}
    />
  );
};

export default NotFound;
